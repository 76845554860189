import { createSelector, createSlice } from "@reduxjs/toolkit";
import { wishListThunks } from "./wishListThunkAction";

const initialState = {
    wishListCount: 0,
    wishListSavedItems: {},
    status: 'idle',
    error: null
};

const wishListSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        updateWishlistCount: (state, action) => {
            const shoppingActivityCount = action.payload || 0;
            state.wishListCount = shoppingActivityCount;
        },
        incrementWishlistCount: (state) => {
            state.wishListCount += 1;
        },
        decrementWishlistCount: (state) => {
            state.wishListCount -= 1;
        },
        handleWishlistStateChange: (state, action) => {
            const { productId, actionType, addedCartlist } = action.payload;
            let index = state.wishListSavedItems?.body && state.wishListSavedItems?.body?.findIndex(item => item?.productId === productId);
            if(index > -1){
                if (actionType === "REMOVE") {
                    state.wishListSavedItems.body?.splice(index, 1);
                }else{
                    state.wishListSavedItems.body[index].addedCartlist = addedCartlist;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(wishListThunks.fetchWishListSaveItems.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(wishListThunks.fetchWishListSaveItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newwishListSavedItems = action.payload || {};
                state.wishListSavedItems = newwishListSavedItems;
            })
            .addCase(wishListThunks.fetchWishListSaveItems.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const { updateWishlistCount, incrementWishlistCount, decrementWishlistCount, handleWishlistStateChange } = wishListSlice.actions;
export const getWishListCountSelector = createSelector((state) => state?.wishlist?.wishListCount, (wishListCount) => wishListCount);
export const getWishListSelector = createSelector((state) => state?.wishlist, (wishlist) => wishlist);
export default wishListSlice.reducer;

