import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartCount: 0,
    cartItems: []
};

/* cart slice */
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateCartCount: (state, action) => {
            const shoppingActivityCount = action.payload || 0;
            state.cartCount = shoppingActivityCount
        },
        incrementCartCount: (state) => {
            state.cartCount += 1;
        },
        decrementCartCount: (state) => {
            state.cartCount -= 1;
        },
        resetCartCount: () => initialState
    }
});

export const getCartSelector = createSelector(((state) => state?.cart?.cartCount), (cartCount) => cartCount);
export const { updateCartCount,incrementCartCount, decrementCartCount, resetCartCount } = cartSlice.actions;
export default cartSlice.reducer;