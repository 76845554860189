import { createSelector, createSlice } from "@reduxjs/toolkit";
import { productThunks } from "./productsThunkAction";

const initialState = {
    products: {},
    status: 'idle',
    error: null
}

/* Product Slice configuration */
const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        resetProductAuthData: () => initialState,
        updateAddedWishlistStatus: (state, action) => {
            const { productId, addedWishlist } = action.payload;
            const productsListData = state.products?.body?.content;
            let index = productsListData && productsListData?.findIndex(product => product?.productId === productId);
            if (index > -1) {
                state.products.body.content[index].addedWishlist = addedWishlist;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(productThunks.fetchProduct.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(productThunks.fetchProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newProducts = action.payload || {};
                state.products = newProducts;
            })
            .addCase(productThunks.fetchProduct.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(productThunks.fetchMoreProducts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(productThunks.fetchMoreProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newProductsData = action.payload?.body?.content || [];
                state.products.body.content = [...state.products?.body?.content, ...newProductsData];
            })
            .addCase(productThunks.fetchMoreProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
})

export const getProductsSelector = createSelector((state) => state?.products, (products) => products);
export const { resetProductAuthData, updateAddedWishlistStatus } = productSlice.actions;
export default productSlice.reducer;