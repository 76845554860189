import AppRouter from "./app/router";

const App = () => {
  
  return (
    <div className="App">
      <main>
        <AppRouter />
      </main>
    </div>
  );
}

export default App;
