import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'
import { retry } from '../../utils/PromiseRetry';
import { PrivateRoute, ProtectedRoute, PublicRoute } from './routesConfig';

const NotFound = lazy(() => retry(() => import('../components/404')));
const Home = lazy(() => retry(() => import('../components/Home')));
const Shop = lazy(() => retry(() => import('../components/Shop')));
const ShopDetails = lazy(() => retry(() => import('../components/ShopDetails')));
const Wishlist = lazy(() => retry(() => import('../components/Wishlist')));
const Checkout = lazy(() => retry(() => import('../components/Checkout')));
const MyOrder = lazy(() => retry(() => import('../components/MyOrder')));
const Reuse = lazy(() => retry(() => import('../components/Reuse')));
const UploadReuseProduct = lazy(() => retry(() => import('../components/Reuse/UploadReuseProduct')));
const RecycleProduct = lazy(() => retry(() => import('../components/Reuse/RecycleProduct')));
const ReUsedProduct = lazy(() => retry(() => import('../components/Reuse/ReUsedProduct')));
const Login = lazy(() => retry(() => import('../components/UserLogin/Login')));
const ResetPassword = lazy(() => retry(() => import('../components/UserLogin/ResetPassword')));
const MerchantRegistration = lazy(() => retry(() => import('../components/UserLogin/MerchantRegistrationForm')));
const SignupForm = lazy(() => retry(() => import('../components/UserLogin/SignupForm')));
const LoginForm = lazy(() => retry(() => import('../components/UserLogin/LoginForm')));
const UserProfile = lazy(() => retry(() => import('../components/UserDashboard/UserProfile')));
const UserTaxInformation = lazy(() => retry(() => import('../components/UserDashboard/UserTaxInformation')));
const BankDetails = lazy(() => retry(() => import('../components/UserDashboard/BankDetails')));
const Signout = lazy(() => retry(() => import('../components/Signout')));
const ProductSummary = lazy(() => retry(() => import('../components/UserDashboard/Products')));
const RedemptionSummary = lazy(() => retry(() => import('../components/UserDashboard/Redemption')));
const RedemptionDetails = lazy(() => retry(() => import('../components/UserDashboard/Redemption/RedemptionDetails')));
const ShipmentSummary = lazy(() => retry(() => import('../components/UserDashboard/Shipment')));
const ShipmentDetails = lazy(() => retry(() => import('../components/UserDashboard/Shipment/ShipmentDetails')));
const AnalyticsSummary = lazy(() => retry(() => import('../components/Analytics/AnalyticsSummary')));

const UploadProduct = lazy(() => retry(() => import('../components/UserDashboard/Products/UploadProduct')));
const UpdateProduct = lazy(() => retry(() => import('../components/UserDashboard/Products/UpdateProduct/index')));

const ExpireLinkPage = lazy(() => retry(() => import('../components/UserLogin/ExpireLinkPage')));

const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <NotFound />,
        children: [
            { path: "", element: <Home /> },
            { path: "shop", element: <PublicRoute element={<Shop isViewBrowseCategory={false} />} /> },
            /* { path: "shop/category/:slug", element: !loginSuccess ? <PublicRoute element={<Shop isViewBrowseCategory={true} />} /> : <PrivateRoute element={<Shop  isViewBrowseCategory={true} /> } /> }, */
            { path: "shop/product/:id", element: <PublicRoute element={<ShopDetails />} /> },
            { path: "wishlist", element: <ProtectedRoute element={<Wishlist individualAccess={true} /> } /> },
            { path: "checkout", element: <ProtectedRoute element={<Checkout individualAccess={true} />} /> },
            { path: "order", element: <ProtectedRoute element={<MyOrder individualAccess={true} />} /> },
            { path: "reuse", element: <Reuse /> },
            { path: "reuse/upload", element: <UploadReuseProduct /> },
            { path: "recycle-products", element: <RecycleProduct /> },
            { path: "reused-products", element: <ReUsedProduct /> },
            { path: "login", element: <PublicRoute element={<Login />} /> },
            { path: ":userSlug/login", element: <PublicRoute element={<LoginForm />} /> },
            { path: "reset-password/:userSlug", element: <ResetPassword /> },
            { path: ":userSlug/signup", element: <PublicRoute element={<SignupForm />} /> },
            { path: "reset-password", element: <ResetPassword /> },
            { path: "merchant/registration", element: <PublicRoute element={<MerchantRegistration />} /> },
            { path: "merchant/signup", element: <PublicRoute element={<SignupForm />} /> },
            { path: "merchant/login", element: <PublicRoute element={<LoginForm />} /> },
            { path: "profile/:customUrl", element: <ProtectedRoute element={<UserProfile merchantAccess={true} />} /> },
            { path: "tax", element: <ProtectedRoute element={<UserTaxInformation merchantAccess={true} />} /> },
            { path: "bank-details", element: <ProtectedRoute element={<BankDetails merchantAccess={true} />} /> },
            { path: "signout", element: <Signout /> },
            { path: "products", element: <ProtectedRoute element={<ProductSummary merchantAccess={true} />} /> },
            { path: "redemption", element: <ProtectedRoute element={<RedemptionSummary merchantAccess={true} isRedemptionSummaryView={true}  />} /> },
            { path: "redemption-details", element: <ProtectedRoute element={<RedemptionSummary merchantAccess={true} isRedemptionSummaryView={false}  />} /> },
            /* { path: "redemption-details", element: <ProtectedRoute element={<RedemptionDetails merchantAccess={true} />} /> }, */
            { path: "shipment", element: <ProtectedRoute element={<ShipmentSummary merchantAccess={true} />} /> },
            { path: "shipment-details/:formType", element: <ProtectedRoute element={<ShipmentDetails merchantAccess={true} />} /> },
            // { path: "analytics", element: <ProtectedRoute element={<AnalyticsSummary merchantAccess={true} />} /> },
            { path: "product/upload", element: <ProtectedRoute element={<UploadProduct merchantAccess={true} />} /> },
            { path: "product/update/:id", element: <ProtectedRoute element={<UpdateProduct merchantAccess={true} />} /> },
            { path: "link-expired", element: <PublicRoute element={<ExpireLinkPage />} /> }

        ],
    },
]);


const AppRouter = () => {
    return (
        <Suspense fallback={
            <span style={{ textAlign: "center", display: "block" }}>
                <div style={{ display: "inline-block", marginTop: "300px" }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={false}
                        timeout={8000}
                    />
                </div>
            </span>}>
            <RouterProvider router={router} />
        </Suspense>
    );
}

export default AppRouter;
