
import { Navigate } from 'react-router-dom';
import { auth_service } from '../../authService';
import { isWebView } from '../../../utils/Common';

export const PrivateRoute = ({ element, pathName }) => {
    if(isWebView()){
        return element;
    }
    if (!auth_service.isAuthenticated()) {
        if(pathName) {
            auth_service.setRedirectionUrl(pathName);
        }
        return <Navigate to="/login" />
    }
    return element;
};

export const PublicRoute = ({ element }) => {
    return element;
};

export const ProtectedRoute = ({ element }) => {
    const userDetails = auth_service.getUserDetails();
    if (!auth_service.isAuthenticated()) {
        return <Navigate to="/login" />
    }
    if ((userDetails?.type === "INDIVIDUAL" && element?.props?.individualAccess) || (userDetails?.type === "MERCHANT" && element?.props?.merchantAccess)) {
        return element;
    }
    return <Navigate to={`/${userDetails?.type === "INDIVIDUAL" ? "shop" : `profile/${userDetails?.customUrl}`}`} />;
};

