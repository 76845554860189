import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../config";
import { updateWishlistCount } from "../wishList/wishListSlice";
import { updateCartCount } from "../cart/cartSlice";

const fetchProduct = createAsyncThunk('products/fetchProducts', async (data, { rejectWithValue }) => {
    try {
        let response;
        if (data?.isLoggedIn) {
            response = await axios.get(Apis.GetUserProductsById(data));
        } else {
            response = await axios.post(Apis.GetGlobalUnathProducts, data);
        }
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch products');
    }
});

const fetchMoreProducts = createAsyncThunk('products/fetchMoreProducts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetUserProductsById(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more products');
    }
});

const fetchShoppingActivityCounts = createAsyncThunk('shop/getCounts', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetShoppingActivityCounts(data));
        if (response?.data?.message === "Success!") {
            dispatch(updateWishlistCount(response?.data?.body?.wishlistCount));
            dispatch(updateCartCount(response?.data?.body?.cartCount));
        }
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch shopping activity counts');
    }
});

export const productThunks = { fetchProduct, fetchMoreProducts, fetchShoppingActivityCounts };

