/* Redux store configuration */
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./features/rootReducer";

export const store = configureStore({
    reducer: rootReducer,
    /* devTools: process.env.NODE_ENV !== 'production', */
})

export default store;
