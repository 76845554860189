/* auth services work for newsepick-web */

import { API_URL, Apis } from "../config";
import queryString from 'query-string';
import axios from "axios";

const isAuthenticated = () => {
    const refreshToken = getRefreshToken();
    const data = getUserDetails();
    if (refreshToken === null || refreshToken === "" || refreshToken === undefined) {
        clearData();
        return false;
    } else {
        return data !== null;
    }
}

const setSessionData = (authResult, response) => {
    localStorage.setItem('userData', JSON.stringify(authResult));
    setRefreshToken(response.headers['authorization']);
}
  
const getUserDetails = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData;
};

const getCurrentUserData = () => {
    const currentUserData = JSON.parse(localStorage.getItem("currentUserData"));
    return currentUserData;
}

const updateUserData = (authResult) => {
    localStorage.setItem('userData', JSON.stringify(authResult));
}

const setRefreshToken = (refreshToken) => {
    localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
}

const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

const logout = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${Apis.UserLogout}`,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(() => {
            clearData();
            resolve();
        }).catch((e) => {
            console.log(e);
            reject(e);
        });
    });

}

const renewAccessToken = () => {
    return new Promise((resolve, reject) => {
        let url =  `${API_URL}/user/renew/token`;
        return axios({
            method: 'post',
            url: url,
            data: queryString.stringify({
                refreshToken: auth_service.getRefreshToken()
            }),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then((response) => {
            if (
                response &&
                response.data &&
                response.data.statusCode === 200
            ) {
                console.log(response.data);
                resolve(response);
            } else {
                console.log(response);
                reject(response);
            }
        }).catch((e) => {
            console.log(e);
            reject(e);
        });
    });
}

const clearData = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('refreshToken');
}


const clearRedirectionUrlData = () => {
    localStorage.removeItem('redirectionUrl');
}

export const auth_service = {isAuthenticated, getUserDetails,logout, getRefreshToken, logout, renewAccessToken, setSessionData, setRefreshToken, clearData, updateUserData ,getCurrentUserData, clearRedirectionUrlData};









