import { combineReducers } from "redux";
import cartReducer from '../features/cart/cartSlice'
import productsReducer from '../features/products/productsSlice'
import wishlistReducer from '../features/wishList/wishListSlice'
import youMightLikeReducer from '../features/youMightLike/youMightLikeSlice'


/* Define root reducer by combining reducers */
const rootReducer = combineReducers({
    cart: cartReducer,
    products: productsReducer,
    wishlist: wishlistReducer,
    youMightLike: youMightLikeReducer

});

export default rootReducer;
