import { createSelector, createSlice } from "@reduxjs/toolkit";
import { youMightLikeThunks } from "./youMightLikeThunkAction";

const initialState = {
    youMightLike: {},
    status: 'idle',
    error: null
}

/* you Might Like slice */
const youMightLikeSlice = createSlice({
    name: 'youMightLike',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(youMightLikeThunks.fetchYouMightlikeProductItems.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(youMightLikeThunks.fetchYouMightlikeProductItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newYouMightLikeItems = action.payload || {};
                state.youMightLike = newYouMightLikeItems;
            })
            .addCase(youMightLikeThunks.fetchYouMightlikeProductItems.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
});

export const getYouMightLikeSelector = createSelector((state) => state?.youMightLike, (youMightLike) => youMightLike);
export default youMightLikeSlice.reducer;