import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../config";

const fetchYouMightlikeProductItems = createAsyncThunk('youmightlike/fetchYouMightlikeProductItems', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetYoumaylikeProductData(data))
        return response?.data
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch you might like list items');
    }
});

export const youMightLikeThunks = { fetchYouMightlikeProductItems };

